/* You can add global styles to this file, and also import other style files */
//@import "../node_modules/@experian/ag-theme-eds/dist/ag-theme-eds.css";
//@import "../node_modules/@experian/ag-theme-eds/dist/ag-theme-eds.min.css";


//@import "../node_modules/ag-grid-community/styles/ag-grid.css";
//@import "../node_modules/ag-grid-community/styles/ag-theme-alpine.css";


@import "../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "../node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

@import '../node_modules/@experian/eds-styles/dist/eds-all.css';
@import '../node_modules/@experian/eds-styles/dist/refactored/eds-components.min.css';

@import 'ngx-toastr/toastr';
@import "@ng-select/ng-select/themes/default.theme.css";

html, body { height: 100%; }

body { 
    margin: 0; 
    font-family: "Roboto", 
    "Helvetica Neue", sans-serif;
    // background-color:#E5E5E5;
    background-color:#F8F8F8;
   }

app-pagination {
    position: relative;
}

.ng-select .ng-clear-wrapper .ng-clear{
    display: none !important;
}
input[type="text"]{
    font-size: 14px;
    line-height: 34px;
    border: 1px solid #ccc;
}

.ag-cell-wrap-text{
    display: flex;
    align-items: center;
    word-break: break-word;
}

.ag-theme-alpine {
    @include ag-theme-alpine();
    .ag-center-cols-container{
        width: 100% !important;
    }
}

.ag-theme-alpine .ag-root-wrapper {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
    border-left: 1px solid #D0D0D0 !important;
}

.ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell {
    border-left: 1px solid #D0D0D0 !important;
    border-top: none !important;
}

.ag-theme-alpine .ag-header-cell-resize::after {
    display: none;
}

.ag-floating-filter-body {
    display: block;
}

.ag-theme-alpine .ag-ltr .ag-floating-filter-button { 
    position:absolute;
    visibility: hidden !important;
}

.ag-theme-alpine input[class^=ag-][type=text] {
    border-color: #8C8C8C !important;
    border-radius: 4px !important;
    min-height: 30px !important;
    font-weight: 400;
}

.toast-center-center {
    top: 10% !important;
    left: 53% !important;
    transform: translate(-50%, -50%) !important;
}

.toast-container {
  font-family: SansBold !important;
}

.toast-container .ngx-toastr {
    display: contents !important;
    color: unset !important;
  }

.ag-theme-alpine input[class^=ag-][type=text]:focus {
    box-shadow: none !important;
}

.ag-theme-alpine .ag-row-selected {
    background-color: #FEF2FF !important;
}

.ag-body {
	.ag-row {
		.ag-cell {
			input[type=checkbox]:checked:after, input[type=radio]:checked:after {
				display: none;
			}
			input[type=checkbox]:after, input[type=radio]:after {
				display: none;
			}
		}
	}
}

.ag-theme-alpine .ag-ltr .ag-cell {
    line-height: 19px !important;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
    color: #426DA9 !important;
}

.ag-theme-alpine [class^=ag-], .ag-theme-alpine [class^=ag-]:focus {
    box-shadow: none !important;
}

.ag-theme-alpine .ag-checkbox-input-wrapper:active {
    box-shadow: none !important;
}

//:first-child
.ag-paging-row-summary-panel, .ag-paging-page-summary-panel {
    display: none;
}

main {
    width:unset;
    max-width:none;
    // background-color:#E5E5E5;
    padding: 72px 66px;
}


@media screen and (max-width: 1000px) {
    main {
        width:unset;
        max-width:none;
        background-color:#F8F8F8;
    }
    .ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
        border-left: none !important;
    }
}
.data-grid-strip {
    height: 40px;
    background: #6D2077 !important;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    color: #ffffff !important;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto';
    display: flex;
    line-height: 23px;
    padding-left: 22px;
    align-items: center;
}

.table-border-header-first {
    border-top-left-radius: 6px;
    border: 1px solid #D0D0D0;
    border-right: none;
}

.table-border-header {
    border: 1px solid #D0D0D0;
    border-right: none;
}

.table-border-header-last {
    border-top-right-radius: 6px;
    border: 1px solid #D0D0D0;
}

.table-border-data-first {
    border: 1px solid #D0D0D0;
    border-top: none;
    border-right: none;
    border-bottom-left-radius: 6px;
}

.table-border-data-multi-first {
    border: 1px solid #D0D0D0;
    border-top: none;
}

.table-border-data {
    border: 1px solid #D0D0D0;
    border-top: none;
    border-right: none;
}

.table-border-data-last {
    border: 1px solid #D0D0D0;
    border-top: none;
}

.error { 
    color: #C60025;
    font-size: 14px;
    margin-top: 11px;
    display: block;
    font-weight: 400;
    font-family: 'Roboto';
}

.custom-tooltip {
    background: #000;
    color: #fff;
    position: absolute;
    width: 150px;
    border: 1px solid #444;
    border-radius: 6px;
    text-transform: capitalize;
    pointer-events: none;
    transition: opacity 1s;
  }
  
.custom-tooltip.ag-tooltip-hiding {
    opacity: 0;
}
  
.custom-tooltip p {
    margin: 5px;
    padding: 5px;
    word-break: break-all;
}
  
.custom-tooltip p:first-of-type {
    font-weight: 400;
    overflow-y: auto;
}

select {
    width: 100%;
    border: 1px solid #8C8C8C;
    border-radius: 4px;
    line-height: 28px;
    padding: 3px 10px;
}

input[type=number] {
    border: 1px solid #8C8C8C;
    border-radius: 4px;
    line-height: 28px;
    padding: 0 10px;
    width: 100%;
}

button:focus {
    outline: none;
}

.popover .arrow{
    right: 0.3em !important;
}

.popover-body{
    background: #F6F6F6 !important;
    border: 1px solid #D0D0D0 !important;
    border-radius: 6px !important;
    padding: 0px !important;
}

.mat-mdc-standard-chip {
    min-height: 24px !important;
    height: auto !important;
    font-size: 14px;
    font-weight: 400 !important;
    border-radius: 4px !important;
    padding: 3px 5px !important;
    margin-top: -2px;
}

#example-chip-list {
    width: 100%;
    height: 95px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{
    top: 0;
}

@media print {
    body {
        zoom: 60%;
        -webkit-print-color-adjust: exact;
    }
    @page {
        size: Tabloid;
    }
}


.border-right{
    border-right: 1px solid #d0d0d0;
    border-bottom-right-radius: 6px;
}
.border-r-only{
    border-right: 1px solid #d0d0d0;
}